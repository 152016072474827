import { createRouter,createWebHistory } from 'vue-router'


const routes = [
    // {
    //     path: '/',
    //     component: () => import('../views/login/index.vue')
    // },
    {
        path: '/',
        component: () => import('../views/index/index.vue')
    },
    {
        path: '/index',
        component: () => import('../views/index/index.vue')
    },
    {
        path: '/login',
        component: () => import('../views/login/index.vue')
    },
    {
        path: '/reg',
        component: () => import('../views/login/signUp.vue')
    },
    {
        path: '/privacy',
        component: () => import('../views/privacy/privacy.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    //history:createWebHashHistory(),
    routes,
})

export default router;
